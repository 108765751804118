<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content>
      <h1>Protocollen</h1>
      <p>
        In deze stroke App zijn verkorte versies van de protocollen voor diagnostiek en behandeling van een herseninfarct opgenomen.
        Hieronder zijn de volledige protocollen weergegeven.
      </p>
      <div>
        <ul>
          <li>
            <pdf-modal name='Intraveneuze trombolyse' attachment='20240830-intraveneuze-trombolyse-IVT-bij-herseninfarct.pdf'>Intraveneuze trombolyse</pdf-modal>
          </li>
          <li>
            <pdf-modal name='Intra-arteriële behandeling' attachment='20241008-protocol-intra-arteriële-behandeling-herseninfarct.pdf'>Intra-arteriële behandeling</pdf-modal>
          </li>
          <li>
            <pdf-modal name='Diagnostiek en behandeling TIA en infarct' attachment='20230907-diagnostiek-en-behandeling-van-TIA-en-herseninfarct.pdf'>Diagnostiek en behandeling TIA en infarct</pdf-modal>
          </li>
          <li>
            <pdf-modal name='Intracerebraal hematoom' attachment='20230907-medische-procedure-intracerebraal-hematoom.pdf'>Intracerebraal hematoom</pdf-modal>
          </li>
          <li>
            <a href='#' onclick="window.open('https://kindertrombose.nl/wp-content/uploads/2022/08/Protocol-AIS-bij-kinderen-juli-2022-def.pdf', '_blank'); return false;">Herseninfarct kinderen</a>
          </li>
        </ul>
      </div>
      <p>
        NB: de lokale actuele protocollen zijn altijd leidend!
      </p>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  name: 'protocols'
}
</script>

<style type="scss" scoped>
  li {
    line-height: 30px;
  }
</style>
